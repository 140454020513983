import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import Assessments from './Assessments/Assessments'
import AssessmentsGrade from './Assessments/Grade/Grade'
import CompositionThemes from './CompositionThemes/CompositionThemes'
import CompositionThemesGrade from './CompositionThemes/Grade/Grade'
import { Page } from './Dashboard.styles'
import $Assessment from '@/services/Assessment'
import $CompositionTheme from '@/services/CompositionTheme'
import Subscription from './Subscription/Subscription'

const Home: React.FC = () => {
  const [ show, setShow ] = useState(false)

  const $assessments = useQuery({
    queryFn: $Assessment.all,
    queryKey: [
      'assessments',
    ],
  })

  const $compositionThemes = useQuery({
    queryFn: $CompositionTheme.all,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    queryKey: [
      'composition-themes',
    ],
  })

  return (
    <>
      <Subscription show={show} setShow={setShow} />

      <Page className="page-home py-5">
        <div className="alert alert-info text-center mb-5 d-none">
          <h1 className="mb-0">
            <span className="text-uppercase">Atenção</span>: As questões do simulado 1 estarão disponíveis a partir do dia 15/07.
          </h1>
        </div>

        <div className="container">
          <div className="row gx-5 gy-3">
            <div className="col col-12 col-md-6 mb-3">
              <div className="card">
                <h3 className="mb-3">Simulados</h3>

                <div className="card-body pb-0">
                  <Assessments data={$assessments.data ?? []} isLoading={$assessments.isPending} />
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-6 mb-3">
              <div className="card">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="mb-0">Notas - Simulados</h3>
                  <button className="btn btn-primary" onClick={() => setShow(true)}>Quero utilizar minhas notas (em breve)</button>
                </div>

                <div className="card-body pb-0">
                  <AssessmentsGrade data={$assessments.data ?? []} isLoading={$assessments.isPending} />
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-6 mb-3">
              <div className="card">
                <h3 className="mb-3">Redações</h3>

                <div className="card-body pb-0">
                  <CompositionThemes data={$compositionThemes.data ?? []} isLoading={$compositionThemes.isPending} />
                </div>
              </div>
            </div>

            <div className="col col-12 col-md-6 mb-3">
              <div className="card">
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h3 className="mb-0">Notas - Redações</h3>
                  <button className="btn btn-primary" onClick={() => setShow(true)}>Quero utilizar minhas notas (em breve)</button>
                </div>

                <div className="card-body pb-0">
                  <CompositionThemesGrade data={$compositionThemes.data ?? []} isLoading={$compositionThemes.isPending} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  )
}

export default Home
