import React, { createContext, useEffect, useState } from 'react'
import useStickyState from '@/hooks/useStickyState'
import axios from 'axios'

type AppContextProps = {
  token: string | null
  setToken: React.Dispatch<React.SetStateAction<string | null>>
  isReady: boolean
}

const AppContext = createContext<AppContextProps>({} as AppContextProps)

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [ isReady, setIsReady ] = useState(false)
  const [ token, setToken ] = useStickyState<string | null>('token', null)

  useEffect(() => {
    if (token && token.length > 0) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      setIsReady(true)
    }
  }, [token])

  return (
    <AppContext.Provider
      value={{
        token,
        setToken,
        isReady,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export default AppContext
