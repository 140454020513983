import React, { useContext } from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom'
import { AuthProvider } from '@/contexts/Auth'
import AppContext from '@/contexts/App'
import App from '@/layouts/App'
import Dashboard from '@/screens/Dashboard/Dashboard'
import CompositionTheme from '@/screens/CompositionTheme/CompositionTheme'
import Login from '@/screens/Auth/Login'
import UserComposition from '@/screens/UserComposition/UserComposition'
import CompositionThemeProvider from '@/contexts/CompositionTheme'

const Router: React.FC = () => {
  const { token } = useContext(AppContext)

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthProvider token={token} />}>
          <Route element={<App />}>
            <Route index element={<Dashboard />} />
            <Route path="/redacao/:id/resultado" element={<UserComposition />} />
          </Route>

          <Route element={<CompositionThemeProvider />}>
            <Route element={<App />}>
              <Route path="/redacao/:id" element={<CompositionTheme />} />
            </Route>
          </Route>
        </Route>

        <Route path="login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
