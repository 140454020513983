import Highcharts from 'highcharts'
import Accessibility from 'highcharts/modules/accessibility'

Accessibility(Highcharts)

Highcharts.setOptions({
  title: {
    text: undefined,
  },
  credits: {
    enabled: false,
  },
  chart: {
    style: {
      fontFamily: 'Evo',
      fontWeight: '400',
      fontSize: '16px',
    },
  },
})
