import React, { createContext, useEffect, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Outlet } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import IUser from '@/interfaces/IUser'
import $Auth from '@/services/Auth'
import useClient from '@/hooks/useClient'

type AuthContextProps = {
  user: IUser
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)

type Props = {
  token: string | null
}

export const AuthProvider: React.FC<Props> = ({ token }) => {
  const { setClient } = useClient()

  const $user = useQuery({
    queryFn: $Auth.me,
    queryKey: [
      'user', token,
    ],
  })

  useEffect(() => {
    if ($user.data) {
      setClient($user.data.client)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$user.data])

  return (
    <AuthContext.Provider
      value={{
        user: $user.data as IUser,
      }}
    >
      {$user.isSuccess ? (
        <Outlet />
      ) : (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </AuthContext.Provider>
  )
}

export default AuthContext
