import React, { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import AppContext from '@/contexts/App'

const Login: React.FC= () => {
  const { setToken } = useContext(AppContext)

  const { search } = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    const params = new URLSearchParams(search)

    if (!params.has('token'))
      return

    const token: string = params.get('token') as string

    if (token.length > 0) {
      setToken(token)

      setTimeout(() => {
        navigate('/')
      }, 500)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, search])

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Spinner animation="border" variant="primary" />
    </div>
  )
}

export default Login
